<template>
  <b-modal
    id="modal-2"
    ok-only
    title="Withdrawal"
    ok-title="Withdrawal"
    size="md"
    @ok="onSubmit"
    >
    <div>
      <b-row>
        <b-col cols="12">
          <b-form>
            <!-- alert -->
            <div v-if="isErrorString">
              <div v-if="errors" class="bg-red-500 text-white py-2 px-4 pr-0 rounded font-bold mb-4 shadow-lg">
                <b-alert variant="danger" show>{{ errors }}</b-alert>
              </div>
            </div>
            <div v-else>
              <div v-if="errors" class="bg-red-500 text-white py-2 px-4 pr-0 rounded font-bold mb-4 shadow-lg">
                <div v-for="(v, k) in errors" :key="k">
                  <p v-for="error in v" :key="error" class="text-sm">
                    <b-alert variant="danger" show>{{ error }}</b-alert>
                  </p>
                </div>
              </div>
            </div>
            <b-row>
              <b-col cols="12">
                Sớ dư của bạn: {{ balance }} {{ unit_money }}<br/>
                Rút tối thiểu 100.000 vnđ
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="6">
                <b-form-group
                  id="input-group-1"
                  label="Số tiền:"
                  label-for="number_id"
                >
                  <b-form-input
                    v-model="amount"
                    id="number_id"
                    type="number"
                    placeholder="amount"
                    required
                    @change="changeMoney"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="6">
              <b-form-group
                id="input-group-2"
                label="Rút về:"
                label-for="banks"
              >
                <b-form-select v-if="bankSelect" v-model="bank_select" required>
                  <BFormSelectOption v-for="(option, k) in bankSelect" v-bind:key="k" v-bind:value="option.id">
                    {{ option.number_id }}
                  </BFormSelectOption>
                </b-form-select>
              </b-form-group>
              </b-col>
            </b-row>
          </b-form>
        </b-col>
      </b-row>
    </div>
  </b-modal>
</template>
<script>
import { 
  BModal, BForm, BFormInput, BFormSelect, BFormSelectOption,
  BFormGroup, BButton, BRow, BCol, BSpinner, BAlert,
} from 'bootstrap-vue'

import ToastificationContentVue from '@core/components/toastification/ToastificationContent.vue'

import { getUserData } from '@/auth/utils'
import useJwt from '@/auth/jwt/useJwt'

const { balance, unit_money } = getUserData()

export default {
  props: {
    bankSelect: {
      type: Array,
      default: () => {},
    },
  },
  components: {
    BModal, BForm, BFormInput, BFormSelect, BFormSelectOption,
    BFormGroup, BButton, BRow, BCol, BSpinner, BAlert,
  },
  methods: {
    onSubmit() {
      this.loading = true
      useJwt.createOrder({
        name: 'Rút tiền về bank',
        amount: this.amount,
        bank_id: this.bank_select,
        description: 'Withdrawal',
        type: 'withdrawal',
      })
        .then(({ data }) => {
          this.loading = false
          if (data.result === true) {
            this.withdrawal_banks = data.data
            this.errors = false // default
            this.$toast({
              component: ToastificationContentVue,
              position: 'top-right',
              props: {
                title: 'Content submit was success!',
                icon: 'CoffeeIcon',
                variant: 'success',
              },
            })
          } else {
            this.errors = data.error
            this.$toast({
              component: ToastificationContentVue,
              position: 'top-right',
              props: {
                title: 'Error!',
                icon: 'CoffeeIcon',
                variant: 'danger',
                text: data.error,
              },
            })
          }
        })
    },
    changeMoney(e) {
      // phải nhỏ hơn balance
      // this.balance = balance - e
    },
  },
  computed: {
    // một computed getter
    isErrorString() {
      // `this` trỏ tới đối tượng vm
      return typeof this.errors === 'string'
    },
  },
  data() {
    return {
      errors: false,
      bank_select: null,
      loading: false,
      balance,
      unit_money,
      amount: 0,
    }
  },
}
</script>

<template>
  <b-modal
    id="modal-1"
    ref="refModal1"
    ok-only
    size="md"
    ok-title="Submit"
    :ok-disabled="errors"
    @ok="onSubmit"
    @hidden="resetModal"
  >
    <div>
      <b-row>
        <b-col cols="12">
          <b-form @submit="onSubmit">
            <!-- alert -->
            <div v-if="isErrorString">
              <div v-if="errors" class="bg-red-500 text-white py-2 px-4 pr-0 rounded font-bold mb-4 shadow-lg">
                <b-alert variant="danger" show>{{ errors }}</b-alert>
              </div>
            </div>
            <div v-else>
              <div v-if="errors" class="bg-red-500 text-white py-2 px-4 pr-0 rounded font-bold mb-4 shadow-lg">
                <div v-for="(v, k) in errors" :key="k">
                  <p v-for="error in v" :key="error" class="text-sm">
                    <b-alert variant="danger" show>{{ error }}</b-alert>
                  </p>
                </div>
              </div>
            </div>
            <b-row>
              <b-col cols="6">
                <b-form-group
                  id="input-group-1"
                  label="Tên Chủ TK:"
                  label-for="full_name"
                >
                  <b-form-input
                    id="full_name"
                    type="text"
                    v-model="form.full_name"
                    placeholder="Full name"
                    required
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group
                  id="input-group-1"
                  label="Số TK:"
                  label-for="number_id"
                >
                  <b-form-input
                    id="number_id"
                    type="number"
                    v-model="form.number_id"
                    placeholder="Account Number"
                    required
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="6">
              <b-form-group
                id="input-group-1"
                label="Chi nhánh:"
                label-for="bank_branch"
              >
                <b-form-input
                  id="bank_branch"
                  type="text"
                  placeholder="branch"
                  v-model="form.branch"
                  required
                ></b-form-input>
              </b-form-group>
              </b-col>
              <b-col cols="6">
              <b-form-group
                id="input-group-2"
                label="Ngân hàng:"
                label-for="banks"
              >
                <b-form-select v-model="form.bank_id" required>
                  <BFormSelectOption v-for="(option, k) in banks_list" v-bind:key="k" v-bind:value="k">
                    {{ option.vn_name }}
                  </BFormSelectOption>
                </b-form-select>
              </b-form-group>
              </b-col>
            </b-row>
          </b-form>
        </b-col>
      </b-row>
    </div>
  </b-modal>
</template>
<script>
import { 
  BModal, BForm, BFormInput, BFormSelect, BFormSelectOption,
  BFormGroup, BButton, BRow, BCol, BSpinner, BAlert,
} from 'bootstrap-vue'

export default {
  watch: {
    editData() {
      this.edit = true
      this.form = { ...this.editData }
    },
  },
  props: {
    editData: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    BModal,
    BForm,
    BFormInput,
    BFormSelect,
    BFormSelectOption,
    BFormGroup,
    BRow,
    BCol,
    BAlert,
  },
  created() {
    this.$http.get('/banks_list')
      .then(({ data }) => {
        this.banks_list = data.data
      })
  },
  methods: {
    resetModal() {
      this.form = {
        full_name: null,
        bank_id: null,
        branch: null,
        number_id: null,
      }
      this.edit = false
    },
    onSubmit() {
      this.$emit('updateItems', this.edit, this.form)
    },
  },
  computed: {
    // một computed getter
    isErrorString() {
      // `this` trỏ tới đối tượng vm
      return typeof this.errors === 'string'
    },
  },
  data() {
    return {
      edit: false,
      errors: false,
      banks_list: [],
      form: {
        full_name: null,
        bank_id: null,
        branch: null,
        number_id: null,
      },
      selected: null,
      withdrawal_banks: [],
    }
  },
}
</script>

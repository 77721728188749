<template>
  <div class="overflow-auto">
    <b-card>
      <b-row>
        <b-col cols="12">
          <div style="padding-left: 20px;margin-bottom: 20px;">
            Số Dư: {{ Intl.NumberFormat().format(balance) }} {{ currency }}
          </div>
        </b-col>
        <b-col cols="12">
          <b-button v-b-modal.modal-1 variant="relief-info"  ><feather-icon icon="PlusIcon" /> Thêm thông tin rút tiền</b-button>
          <b-button v-if="!withdrawal" v-b-modal.modal-2 variant="relief-success" style="margin-left:5px"><feather-icon icon="SendIcon" /> Tạo lệnh rút</b-button>
          <span id="withdrawal" v-if="withdrawal">Đang chờ xác nhận rút {{ Intl.NumberFormat().format(withdrawal_amount) }}  {{ currency }}</span>
          <span style="margin-left:10px"> tối thiểu. {{ Intl.NumberFormat().format(min_withdrawal) }} {{ currency }}</span>
        </b-col>
      </b-row>
      <b-overlay :show="loading">
        <b-row>
          <b-col cols="12">
            <b-row
              class="row-card row-title"
            >
              <b-col cols="4">
                Tên ngân hàng
              </b-col>
              <b-col cols="3">
                Số Tài Khoản
              </b-col>
              <b-col cols="3">
                Chủ Tài Khoản
              </b-col>
              <b-col cols="2">...</b-col>
            </b-row>
            <b-row
              v-for="(infoCard, k) in items"
              v-bind:key="infoCard.id"
              class="row-card"
            >
              <b-col cols="4">
                {{ infoCard.bank_name }}
              </b-col>
              <b-col cols="3">
                {{ infoCard.number_id }}
              </b-col>
              <b-col cols="3">
                {{ infoCard.full_name }}
              </b-col>
              <b-col cols="2">
                <b-button
                  v-b-modal.modal-1
                  size="sm"
                  variant="relief-warning"
                  @click="onEdit(k)"
                ><feather-icon icon="EditIcon" /></b-button>
                <b-button
                  v-b-modal.modal-3
                  size="sm"
                  variant="relief-danger"
                  @click="onDelete(infoCard.id)"
                ><feather-icon icon="DeleteIcon" /></b-button>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-overlay>
    </b-card>
    <NewBank
      v-if="edit_data"
      :edit-data.sync="edit_data"
      @updateItems="updateItems"
    />
    <withdrawal
      v-if="items"
      :bank-select="items"
    />
  </div>
</template>

<script>

import { BCard, BCol, BRow, BButton, BOverlay, } from 'bootstrap-vue'

import ToastificationContentVue from '@core/components/toastification/ToastificationContent.vue'
import NewBank from './components/NewBank.modal.vue'

import Withdrawal from './components/Withdrawal.modal.vue'
import useJwt from '@/auth/jwt/useJwt'

export default {
  methods: {
    onEdit(id) {
      this.edit_data = { ...this.items[id] }// this.items[id]
    },
    onDelete(id) {
      this.loading = true
      useJwt.delBank(id)
        .then(({ data }) => {
          if (data.result === true) {
            this.loading = false
            // filter
            this.items = this.items.filter(bank => bank.id !== id)
          } else {
            this.$toast({
              component: ToastificationContentVue,
              position: 'top-right',
              props: {
                title: 'Error!',
                icon: 'CoffeeIcon',
                variant: 'danger',
                text: data.error,
              },
            })
          }
        })
    },
    updateItems(t, form) {
      let rqbank = () => {}
      this.loading = true
      if (t) {
        rqbank = useJwt.editBank(this.edit_data.id, form)
      } else {
        rqbank = useJwt.addBank(form)
      }
      rqbank.then(({ data }) => {
        this.loading = false
        if (data.result === true) {
          this.errors = false // default
          if (t) {
            this.items = this.items.map((item, k) => item.id === this.edit_data.id ? form : item)
          } else {
            this.items.push(data.data)
          }
          this.$toast({
            component: ToastificationContentVue,
            position: 'top-right',
            props: {
              title: 'Content submit was success!',
              icon: 'CoffeeIcon',
              variant: 'success',
            },
          })
        } else {
          this.errors = data.error
          this.$toast({
            component: ToastificationContentVue,
            position: 'top-right',
            props: {
              title: 'Error!',
              icon: 'CoffeeIcon',
              variant: 'danger',
              text: data.error,
            },
          })
        }
      })
    },
  },
  data() {
    return {
      loading_newbank: false,
      loading: false,
      perPage: 10,
      currentPage: 1,
      items: [],
      edit_data: {},
      currency: 'vnd',
      balance: 0,
      min_withdrawal: process.env.VUE_APP_MIN_WITHDRAWAL_VN,
      withdrawal: false,
      withdrawal_amount: 0,
    }
  },
  components: {
    BOverlay,
    Withdrawal,
    NewBank,
    BButton,
    BCol,
    BRow,
    BCard,
  },
  computed: {
    rows() {
      return this.items.length
    },
  },
  mounted() {
    this.loading = true
    this.$http.get('banks').then(({ data }) => {
      this.loading = false
      this.balance = data.balance
      this.currency = data.currency
      this.items = data.data
      if (data.withdrawal !== null) {
        this.withdrawal = true
        this.withdrawal_amount = data.withdrawal.amount
      }
    })
  },
}
</script>
<style>
  .row-card{
    padding-top: 10px;
    border-bottom-style: solid;
    border-bottom-width: thin;
  }
  .row-title {
    font-style: italic;
    font-size: 12px;
    font-weight: bold;
  }
  #withdrawal {
    color: red;
    font-weight: bold;
    font-size: 15px;
  }
</style>
